@use "../config" as *;

.waf-listing {
    .waf-head .title{
        @extend %neutral-50;
    }
    .layout-wrapper{
        position: relative;
    }
    .img-4by3 {
        @include listing-card(overlay);
        .article-content{
            @extend %pt-0;
            .article-meta{
                .meta-category{
                    bottom : calc(100% + 1rem);
                    @extend %absolute;
                    @extend %primary-500;
                }
                
            }
        }
    }
    .img-1by1{
        @include listing-card(vertical);
        .article-content{
            @extend %pt-9;
            .meta-category{
                bottom : calc(100% - 3.5rem);
                @extend %absolute;
            }
        }
    }
    .first-list {
        --_fontsize: 16;
        // --_line-height: 2.4;
        gap: var(--space-3);
        @extend %flex-column;
        @extend %mb-3;
    }
    .second-list {
        --_fontsize: 14;
        // --_line-height:1.8;
        display: grid;
        gap: var(--space-3);
        grid-template-columns: repeat(4, 1fr);
        .img-1by1 {
            grid-column: span 2;
        }
        .img-4by3 {
            --_fontsize: 14;
            // --_line-height: 2.4;
            grid-column: span 4;
        }
    }
    .article-title {
        font-weight: 500;
        @extend %mb-3;
    }
    .article-content {
        @extend %p-3;
        .reaction-section {
            @extend %mt-0;
        }
    }
    .article-item{
        @extend %article-item-scale;
    }
}

@media screen and (min-width: $tablet-min-width) {
    .waf-listing {
        .article-wrap{
            height: 100%;
            .article-thumbnail{
              height: 100%
            }
        }
        .img-16by9 {
            @include listing-card(overlay);
            width: 67%;
            .article-content{
                padding-top: 0;
                .article-meta{
                    .meta-category{
                        bottom : calc(100% + 1rem);
                        position: absolute;
                        color:clr(primary-500);
                    }
                    
                }
            }
        }
        .first-list {
            --_fontsize:20;
            // --_line-height: 2.7;
            flex-direction: row;
            .img-4by3 {
                @include listing-card(vertical);
                &:hover{
                    .article{
                        &-content{
                            background: clr(secondary-1000);
                            a,
                            .icon-b-share::before {
                                color: clr(neutral-50);
                            }
                           
                            .article-title{
                              color: clr(neutral-50);
                            }

                        }
                        &-meta {
                            .meta {
                                color: clr(neutral-50);
                                &::after {
                                    background-color: var(--neutral-900);
                                }
                            }
                            .meta-category{
                                background: clr(neutral-1000,1);
                            }
                          
                           
                        }
                    }
                }
                .article-content{
                    padding-top: var(--space-9);
                        .meta-category{
                            bottom : calc(100% - 3.5rem);
                            position: absolute;
                        }
                    }
                width: 33%;
                    .img-box{
                        height: 100%;
                    }
            }
        }

        .second-list {
            // --_line-height:2.4;
            grid-template-columns: repeat(4, 1fr);
            .img-1by1 {
                grid-column: span 1;
                .article {
                    &-wrap {
                        height: 100%;
                    }
                    &-content {
                        height: 100%;
                    }
                }
                &:hover{
                    .article{
                        &-content{
                            background: clr(secondary-1000);
                            a,
                            .icon-b-share::before {
                                color: clr(neutral-50);
                            }
                            .article-title{
                                color: clr(neutral-50);
                            }
                        }
                        &-meta {
                            .meta {
                                color: clr(neutral-50);
                                &::after {
                                    background-color: var(--neutral-900);
                                }
                            }
                            .meta-category{
                                background: clr(neutral-1000,1);
                            }
                        }
                    }
                }
            }
            .img-4by3 {
                grid-column: span 2;
            }
        }
    }
}
